import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { LocalStorageService } from '../../services/local-storage-service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as $ from 'jquery';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    public apiInputs = { "project_id": "", "supplier_id": "", "token": "", "ip_address": "", "uid": "", "browser_name": "", "device_type": "", "utm_source": "", "utm_medium": "", "utm_term": "", "campaign_id": "" };
    public projectid: any = '';
    public supplierid: any = '';
    public token: any = '';
    public clientlogo = '';
    public logoimage = '';
    public ipAddress = "";
    public message = "Welcome to cluster";
    public urldata = "";
    public isquestion = "";
    public is_button = 1;
    public uid = "";
    public customer = "";
    public browserName = '';
    public browserVersion = '';
    public embeded_url: any = "";
    public utm_source = '';
    public utm_medium = '';
    public utm_term = '';
    public campaign_id = '';

    constructor(
        public apiService: ApiService,
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private localStorageService: LocalStorageService,
        public http: HttpClient,
        public sanitized: DomSanitizer,




    ) {
        this.activatedRoute.queryParams.subscribe((params: Params) => {
           

            if (typeof params.projectid != 'undefined' && params.projectid != '' && params.projectid != null &&
                typeof params.supplierid != 'undefined' && params.supplierid != '' && params.supplierid != null
            ) {
                this.projectid = params.projectid;
                this.supplierid = params.supplierid;
                this.token = params?.token;
                this.uid = params?.uid;
                this.apiInputs.project_id = this.projectid;
                this.apiInputs.supplier_id = this.supplierid;
                this.apiInputs.token = this.token;
                this.apiInputs.uid = this.uid;
                
                if (typeof params.utm_source != 'undefined' && params.utm_source != '' && params.utm_source != null) {
                    this.apiInputs.utm_source = params.utm_source;
                }
                if (typeof params.utm_medium != 'undefined' && params.utm_medium != '' && params.utm_medium != null) {
                    this.apiInputs.utm_medium = params.utm_medium;
                }
                if (typeof params.utm_term != 'undefined' && params.utm_term != '' && params.utm_term != null) {
                    this.apiInputs.utm_term = params.utm_term;
                }
                if (typeof params.campaign_id != 'undefined' && params.campaign_id != '' && params.campaign_id != null) {
                    this.apiInputs.campaign_id = params.campaign_id;
                }
               

            }
            else {
                this.router.navigate(['not-found']);
            }
        });
    }

    ngOnInit(): void {

        this.localStorageService.removeItem("currentCustomer");
        this.get_feedback_list();
        this.browserVersion = this.detectBrowserVersion();
        var ua = navigator.userAgent;

    }

    get_feedback_list(): any {
        this.browserName = this.detectBrowserName();
        var ismobile = this.isMobile();
        var isMobileTablet = this.isMobileTablet();
        if (ismobile == true) {
            this.apiInputs.device_type = "mobile";
        } else if (isMobileTablet == true) {
            this.apiInputs.device_type = "tablet";
        } else {
            this.apiInputs.device_type = "desktop";
        }
        
        this.localStorageService.setItem("currentCustomer", this.token);
        this.showLoader();
        //   this.http.get("http://api.ipify.org/?format=json").subscribe((res:any)=>{
        this.apiInputs.browser_name = this.browserName;
        //console.log('this.apiInputs.ip_address',this.apiInputs.ip_address);
        this.apiService.postApi(this.apiInputs, 'user/getProjectData').subscribe((result) => {
            this.hideLoader();

            if (result.status == 1) {
                this.logoimage = result.client_logo;
                this.clientlogo = this.apiService.clientlogourl;
                this.message = result.message;
                this.isquestion = result.question;
                if (!this.message) {
                    this.router.navigate(
                        ['survey/survey'],
                        { queryParams: { projectid: this.projectid, supplierid: this.supplierid, token: this.token } }
                    );
                }
            } else if (result.status == 0) {
                this.message = result.message;
                this.is_button = 0;
            } else if (result.status == 11) {
                decodeURI(result.url);
                this.embeded_url = this.sanitized.bypassSecurityTrustResourceUrl(result.url);
            } else {
                decodeURI(result.url);
                window.location = result.url;
            }

        }, (err) => {

            this.hideLoader();
            this.router.navigate(['not-found']);
            console.log("Client/feedback_list", JSON.stringify(err));
        });
        // })
    }

    showLoader() {
        $("#loader_form").show();
    }

    hideLoader() {
        $("#loader_form").hide();
    }

    routeCustomrAuth() {
        if (this.isquestion) {
            if (
                typeof this.projectid != 'undefined' && this.projectid != null && this.projectid != '' &&
                typeof this.supplierid != 'undefined' && this.supplierid != null && this.supplierid != ''

            ) {
                this.router.navigate(
                    ['survey/survey'],
                    { queryParams: { projectid: this.projectid, supplierid: this.supplierid, token: this.token } }
                );
            }
            else {
                this.apiService.showToast("Something went wrong.");
            }
        } else {
            this.apiService.postApi(this.apiInputs, 'user/getClientUrl').subscribe((result) => {
                if (result.status == 1) {
                    decodeURI(result.url);
                    // window.location = result.url;
                }
            });
        }

    }

    getIPAddress() {
        this.http.get("http://api.ipify.org/?format=json").subscribe((res: any) => {
            this.ipAddress = res.ip;
        });
    }

    detectBrowserName() {
        const agent = window.navigator.userAgent.toLowerCase()
        switch (true) {
            case agent.indexOf('edge') > -1:
                return 'edge';
            case agent.indexOf('opr') > -1 && !!(<any>window).opr:
                return 'opera';
            case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
                return 'chrome';
            case agent.indexOf('trident') > -1:
                return 'ie';
            case agent.indexOf('firefox') > -1:
                return 'firefox';
            case agent.indexOf('safari') > -1:
                return 'safari';
            default:
                return 'other';
        }
    }

    detectBrowserVersion() {
        var userAgent = navigator.userAgent, tem,
            matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

        if (/trident/i.test(matchTest[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (matchTest[1] === 'Chrome') {
            tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
        matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = userAgent.match(/version\/(\d+)/i)) != null) matchTest.splice(1, 1, tem[1]);
        return matchTest.join(' ');
    }

    isMobile() {
        var check = false;
        (function (a) {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
                check = true;
        })(navigator.userAgent || navigator.vendor);
        return check;
    };

    isMobileTablet() {
        var check = false;
        (function (a) {
            if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4)))
                check = true;
        })(navigator.userAgent || navigator.vendor);
        return check;
    }
}
