<div *ngIf="embeded_url !=''">
    <iframe width="100%" style="min-height: 90vh;" [src]="embeded_url"
        title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
</div>
<body class="style_3" *ngIf="embeded_url ==''">
   
    <div class="wrapper_centering" style="margin:0">
        <div class="container_centering">
            <div class="container">
                
                <div class="row justify-content-between">
                    <div class="col-xl-6 offset-xl-3">
                        <div class="main_title_1 text-center">
                            <!-- <h3><img src="{{clientlogo}}{{logoimage}}" style="width: 80%" alt="Logo"> </h3> -->
                            <!-- <h3><img src="assets/img/mm-logo.png"alt="Logo"> </h3> -->
                            <h1><p innerHTML="{{this.message}}"></p></h1>
                            <div *ngIf="this.is_button ==1">
                             <button (click)="routeCustomrAuth()" name="process" class="submit">Next</button>
                            </div>
                        </div>
                    </div>
                    
                    <!-- /col -->
                </div>
            </div>
            <!-- /row -->
        </div>
        <!-- /container_centering -->
    </div>
    <!-- /wrapper_centering -->
</body>